<template>
	<div v-if="this.isLoaded" class="bcard-wrapper">
		<div class="d-flex mb-2 justify-content-between">
			<h4>{{ detailTitle }}</h4>
			<megau-button
				v-show="this.showAddNewButton"
				classprop="btn btn-success ml-2"
				icon="plus"
				titlePosition="right"
				:title="$t('addanewarticle')"
				@handleClick="insertNewTab()"
			></megau-button>
		</div>
		<b-tabs content-class="mt-3">
			<template v-for="article in this.SpArticleList">
				<seo-page-article-tab
					@dataChanged="fetchData()"
					:SeoPageId="SeoPageId"
					:SeoArticle="article"
					:key="articleKey(article.SparticleId)"
				></seo-page-article-tab>
			</template>
		</b-tabs>
	</div>
</template>
<script>
import vue from 'vue';
import { mapGetters } from 'vuex';

import seoArticleDetail from './seo-page-article.detail';
import { tabsModel, detailModel } from './seo-page-article.models';
import formFields from './seo-page-article.fields';

import * as links from '../../.././router/links';
import loading from '../../../mixins/loading.mixin';
import http from '@/services/helpers/http';

import vueFormGenerator from 'vue-form-generator';
vue.use(vueFormGenerator);

export default {
	name: 'SeoArticleTabs',
	mixins: [loading],
	components: {
		'seo-page-article-tab': seoArticleDetail,
	},
	props: {
		SeoPageId: { type: Number, default: 0 },
		CountryId: { type: Number, default: 0 },
		SpageLanguage: { type: String, default: 'sk' },
	},
	data() {
		return tabsModel();
	},
	computed: {
		detailTitle() {
			return this.$i18n.t('texts');
		},
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		async fetchData() {
			this.startLoading(false);
			let resp = await http.get(this.controllerName + '/ListBySeoPage', {
				seoPageId: this.SeoPageId,
				countryId: this.CountryId,
				languageCode: this.SpageLanguage,
			});

			if (resp.data.length) {
				this.SpArticleList = resp.data;
				this.showAddNewButton = true;
			} else {
				let emptyModel = await this.createNewArticleModel();
				this.SpArticleList = [emptyModel];
			}
			this.stopLoading();
		},
		async insertNewTab() {
			let hasNewTab = this.SpArticleList.filter((x) => x.SparticleId == 0);
			if (!hasNewTab.length) {
				let emptyModel = await this.createNewArticleModel();
				this.SpArticleList.push(emptyModel);
			}
		},
		async createNewArticleModel() {
			let resp = await http.get('SiteUrl/GetUrlByCountryAndLang', {
				countryId: this.CountryId,
				languageCode: this.SpageLanguage,
			});

			let emptyArticleModel = detailModel().model;
			emptyArticleModel.DomainUrl = resp.data;
			emptyArticleModel.SpageId = this.SeoPageId;
			emptyArticleModel.isActive = true;
			return emptyArticleModel;
		},
		articleKey(articleId) {
			return articleId + Math.random();
		},
	},
};
</script>
