const service = {
	articleFormTranslations(formFields, i18n) {
		formFields.SparticleCaption.label = i18n.t('titleofarticle');
		// formFields.SparticleText.label = i18n.t('text - slovko');
		formFields.SparticleIsVerified.label = i18n.t('articleischecke');
		formFields.SparticleIsVerified.textOn = i18n.t('yes2220');
		formFields.SparticleIsVerified.textOff = i18n.t('no2221');
		formFields.SparticleDateCreated.label = i18n.t('created:');
	},
};

export default service;
