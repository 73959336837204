import formFields from './seo-page-article.fields';

function detailModel() {
	return {
		controllerName: 'SeoPageArticle',
		showNoteModal: false,
		noteText: '',
		showDeleteButton: false,
		articlesDropdown: [],
		schema: {
			groups: formFields.groups(),
		},
		formOptions: {
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
		},
		model: {
			SparticleId: 0,
			SpageId: 0,
			SparticleCaption: '',
			SparticleText: '',
			SparticlePhoto1: '',
			SparticlePhoto2: '',
			SparticlePhoto3: '',
			SparticleIsVerified: false,
			SparticleDateCreated: null,
			SparticleDateVerified: null,
			SparticleCreatedByUser: null,
			SparticleVerifiedByUser: null,
			SpageLanguage: null,
			DomainUrl: null,
		},
	};
}

function tabsModel() {
	return {
		controllerName: 'SeoPageArticle',
		SpArticleList: [],
		showAddNewButton: false,
	};
}

export { detailModel, tabsModel };
